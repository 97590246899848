import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'
import { default as FormControlLabel } from '@material-ui/core/FormControlLabel'
import { default as MuiRadio, RadioProps as MuiRadiosProps } from '@material-ui/core/Radio'
import { useRadioGroup } from '@material-ui/core/RadioGroup'

import { ErrorMessageAndHelperText, useDeviceType, useUtilityStyle } from '../../..'

export type BaseRadioProps = Omit<MuiRadiosProps, 'color'> & {
  maxWidth?: string | number
  label?: string | React.ReactNode
  readOnly?: boolean
  condensed?: boolean
  helperText?: string
  error?: boolean
  errorMessage?: string
}

const SIZE = 22

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      gap: theme.spacing(2),
      flex: 1,
      boxSizing: 'border-box',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(3),
      marginLeft: 0,
      alignItems: 'flex-start',
      pointerEvents: ({ readOnly, disabled }: { readOnly: boolean; disabled: boolean }) =>
        readOnly || disabled ? 'none' : 'auto',
    },
    colorPrimary: {
      '&.Mui-checked': {
        borderWidth: 7,
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-checked:hover': {
        backgroundColor: 'transparent',
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[10],
      },
      '&.Mui-disabled': {
        borderColor: theme.palette.grey[30],
      },
      '&.Mui-disabled.Mui-checked': {
        borderColor: theme.palette.grey[30],
      },
    },
    radio: {
      margin: 0,
      padding: 0,
      width: SIZE,
      height: SIZE,
      borderRadius: '50%',
      borderWidth: 2,
      borderStyle: 'solid',
      boxSizing: 'border-box',
      backgroundColor: theme.palette.common.white,
      borderColor: theme.palette.grey[60],
    },
    readOnly: {
      '&.Mui-checked': {
        borderColor: `${theme.palette.grey[60]} !important`,
      },
    },
    disabled: {
      borderColor: theme.palette.grey[30],
    },
  }),
  { name: 'BaseRadio' },
)

const BaseRadio: React.FC<BaseRadioProps> = (props) => {
  const {
    className,
    checked: checkedProps,
    condensed,
    defaultChecked,
    defaultValue,
    disabled,
    error,
    errorMessage,
    helperText,
    id,
    label,
    maxWidth = 360,
    readOnly,
    value,
    name,
    ...other
  } = props

  const classes = useStyles(props)
  const { isMobile } = useDeviceType()
  const utilityClasses = useUtilityStyle({ maxWidth: !isMobile ? maxWidth : null })
  const radioGroup = useRadioGroup()

  const checked =
    checkedProps ||
    (radioGroup.value === undefined || radioGroup.value === null ? false : radioGroup.value.toString() === value)

  return (
    <>
      <FormControlLabel
        aria-label={`${name}-${value}`}
        name={`${name}-${value}`}
        className={clsx(classes.root, className, utilityClasses.maxWidth)}
        label={label}
        labelPlacement='end'
        value={value}
        control={
          <MuiRadio
            classes={{
              root: clsx(classes.radio, { [classes.readOnly]: readOnly }),
              colorPrimary: classes.colorPrimary,
            }}
            color='primary'
            readOnly={readOnly}
            checked={checked}
            disabled={disabled}
            icon={null}
            checkedIcon={null}
            disableRipple
            {...other}
          />
        }
      />
      <ErrorMessageAndHelperText errorMessage={errorMessage} helperText={helperText} id={id || name} />
    </>
  )
}

export { BaseRadio }
