import { getLocationService, Location } from '@guiker/base-entity'
import { slugifyLowercase } from '@guiker/lodash'

import { TFunction } from './index'

export const getLocalizedCityFromLocation = (t: TFunction, location: Location) => {
  return getLocalizedCity(t, { countryCode: location?.country?.countryCode, citySlug: location?.city?.slug })
}

export const getLocalizedCity = (
  t: TFunction,
  { countryCode, citySlug }: { countryCode: string; citySlug: string },
) => {
  return t(`common:countries.${countryCode?.toUpperCase()}.cities.${slugifyLowercase(citySlug)}.name`)
}

export const getLocalizedCountryFromLocation = (t: TFunction, { location }: { location: Location }) => {
  return getLocalizedCountry(t, { countryCode: location?.country?.countryCode })
}

export const getLocalizedCountry = (t: TFunction, { countryCode }: { countryCode: string }) => {
  return t(`common:countries.${countryCode.toUpperCase()}.name`)
}

export const getLocalizedStateFromLocation = (t: TFunction, { location }: { location: Location }) => {
  return getLocalizedState(t, { countryCode: location?.country?.countryCode, stateCode: location?.state?.stateCode })
}

export const getLocalizedState = (
  t: TFunction,
  { countryCode, stateCode }: { countryCode: string; stateCode: string },
) => {
  return t(`common:countries.${countryCode.toUpperCase()}.states.${stateCode.toUpperCase()}`)
}

export const getLocalizedNeighbourhoodFromLocation = (t: TFunction, { location }: { location: Location }) => {
  return getLocalizedNeighbourhood(t, {
    countryCode: location.country.countryCode,
    citySlug: location.city.slug,
    neighbourhoodSlug: location.neighbourhood?.slug,
  })
}

export const getLocalizedNeighbourhood = (
  t: TFunction,
  { countryCode, citySlug, neighbourhoodSlug }: { countryCode: string; citySlug: string; neighbourhoodSlug: string },
) => {
  return citySlug
    ? t(
        `common:countries.${countryCode.toUpperCase()}.cities.${slugifyLowercase(citySlug)}.neighbourhoods.${
          neighbourhoodSlug
            ? slugifyLowercase(neighbourhoodSlug)
            : getLocationService().getCityBySlug(citySlug)?.city.defaultNeighbourhood
        }`,
      )
    : t('common:address.defaultNeighbourhood')
}
