import { zh as authentication } from './authentication'
import { zh as commonBackgroundCheck } from './common/background-check'
import { zh as common } from './common/base'
import { zh as commonBaseListing } from './common/base-listing'
import { zh as commonBooking } from './common/booking'
import { zh as commonComponents } from './common/components'
import { zh as commonInvestmentAssumption } from './common/investment-assumption'
import { zh as commonPayment } from './common/payment'
import { zh as commonPayout } from './common/payout'
import { zh as commonPropertySale } from './common/property-sale'
import { zh as commonPropSharing } from './common/propsharing'
import { zh as commonRealEstate } from './common/real-estate'
import { zh as commonRentPayment } from './common/rent-payment'
import { zh as commonRentalListing } from './common/rental-listing'
import { zh as commonTask } from './common/task'
import { zh as commonTenantPreference } from './common/tenant-preference'
import { zh as errors } from './errors'
import { zh as listing } from './listing'
import { zh as mainAccount } from './main/account'
import { zh as mainBackgroundCheck } from './main/background-check'
import { zh as mainBlog } from './main/blog'
import { zh as mainBooking } from './main/booking'
import { zh as mainBookingApplicant } from './main/booking-applicant'
import { zh as mainBookingDocument } from './main/booking-document'
import { zh as mainBookingRentPayment } from './main/booking-rent-payment'
import { zh as mainInvestments } from './main/investments'
import { zh as mainMyInvestments } from './main/my-investments'
import { zh as mainNotification } from './main/notification'
import { zh as mainPayment } from './main/payment'
import { zh as mainPayout } from './main/payout'
import { zh as mainPropertySale } from './main/property-sale'
import { zh as mainPropSharing } from './main/propsharing'
import { zh as mainRentalListing } from './main/rental-listing'
import { zh as mainRentalListingInquiry } from './main/rental-listing-inquiry'
import { zh as mainStatistic } from './main/statistic'
import { zh as mainTenantApplication } from './main/tenant-application'
import { zh as mainTenantPreference } from './main/tenant-preference'
import { zh as mainWebapp } from './main/webapp'
import { zh as officeBooking } from './office/booking'
import { zh as officeLeaseContract } from './office/lease-contract'
import { zh as officeNote } from './office/note'
import { zh as officePayment } from './office/payment'
import { zh as officePayout } from './office/payout'
import { zh as officePropertySale } from './office/property-sale'
import { zh as officePropSharing } from './office/propsharing'
import { zh as officeRealEstate } from './office/real-estate'
import { zh as officeRentPayment } from './office/rent-payment'
import { zh as officeRentalListing } from './office/rental-listing'
import { zh as officeRentalListingCreation } from './office/rental-listing-creation'
import { zh as officeRentalListingInquiry } from './office/rental-listing-inquiry'
import { zh as officeRentalListingLeadListing } from './office/rental-listing-lead-listing'
import { zh as officeSearch } from './office/search'
import { zh as officeUser } from './office/user'
import { zh as officeWebapp } from './office/webapp'
import { zh as payout } from './payout'

export const zh = {
  authentication,
  errors,
  common,
  listing,
  payout,
  'common-backgroundCheck': commonBackgroundCheck,
  'common-baseListing': commonBaseListing,
  'common-booking': commonBooking,
  'common-components': commonComponents,
  'common-investmentAssumption': commonInvestmentAssumption,
  'common-rentalListing': commonRentalListing,
  'common-PropertySale': commonPropertySale,
  'common-propsharing': commonPropSharing,
  'common-payment': commonPayment,
  'common-payout': commonPayout,
  'common-realEstate': commonRealEstate,
  'common-rentPayment': commonRentPayment,
  'common-task': commonTask,
  'common-tenantPreference': commonTenantPreference,
  'main-account': mainAccount,
  'main-backgroundCheck': mainBackgroundCheck,
  'main-blog': mainBlog,
  'main-booking': mainBooking,
  'main-bookingApplicant': mainBookingApplicant,
  'main-bookingDocument': mainBookingDocument,
  'main-bookingRentPayment': mainBookingRentPayment,
  'main-propsharing': mainPropSharing,
  'main-investments': mainInvestments,
  'main-myInvestments': mainMyInvestments,
  'main-notification': mainNotification,
  'main-propertySale': mainPropertySale,
  'main-payment': mainPayment,
  'main-payout': mainPayout,
  'main-rentalListing': mainRentalListing,
  'main-rentalListingInquiry': mainRentalListingInquiry,
  'main-statistic': mainStatistic,
  'main-tenantApplication': mainTenantApplication,
  'main-tenantPreference': mainTenantPreference,
  webapp: mainWebapp,
  'main-webapp': mainWebapp,
  'office-booking': officeBooking,
  'office-propsharing': officePropSharing,
  'office-leaseContract': officeLeaseContract,
  'office-propertySale': officePropertySale,
  'office-rentalListing': officeRentalListing,
  'office-rentalListingCreation': officeRentalListingCreation,
  'office-rentalListingInquiry': officeRentalListingInquiry,
  'office-rentalListingLeadListing': officeRentalListingLeadListing,
  'office-note': officeNote,
  'office-payout': officePayout,
  'office-payment': officePayment,
  'office-realEstate': officeRealEstate,
  'office-rentPayment': officeRentPayment,
  'office-search': officeSearch,
  'office-user': officeUser,
  'office-webapp': officeWebapp,
}
