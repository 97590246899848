import React, { createContext, useCallback, useMemo } from 'react'

import { supportedLanguages } from '@guiker/base-entity'
import { theme } from '@guiker/components-core'
import { generateUseContext } from '@guiker/react-context'

import { useLayoutContext } from '../../../hooks'
import { HorizontalNavigationTabs } from '.'
import { InPageNavigationItem } from './InPageNavigationItem'
import { StickySideMenu } from './StickySideMenu'
import { useSideMenuActiveItem } from './use-menu-active-item'
import { useScrollToAnchor } from './use-scroll-to-anchor'

type Direction = 'row' | 'column'

type InPageNavigationContext = {
  currentLocation: string
  direction: Direction
  isActiveItem: (item: Pick<InPageNavigationItem, 'href'>) => boolean
  hasActiveNestedItem: (items: Pick<InPageNavigationItem, 'href'>[]) => boolean
  items: InPageNavigationItem[]
}

/**
 * @param offset - viewport scrollTop offset
 * @param threshold - region of viewport for scroll highlight detection
 * */
type InPageNavigationContextProviderProps = React.PropsWithChildren & {
  items: InPageNavigationItem[]
  currentLocation?: string
  className?: string
  disabled?: boolean
  offset?: number
  threshold?: number
  width?: number | string
}

const InPageNavigationContext = createContext<InPageNavigationContext>(null)

const DEFAULT_OFFSET = theme.dimensions.appBar.height.desktop
const DEFAULT_THRESHOLD = 0.5
const SANITIZATION_REGEX = `^(\/*(${supportedLanguages.join('|')})*\/+)`

export const InPageNavigation: React.FC<InPageNavigationContextProviderProps> = ({
  currentLocation,
  items,
  offset = DEFAULT_OFFSET,
  threshold = DEFAULT_THRESHOLD,
  ...otherProps
}) => {
  const { isAtLeastMidSizeDesktop } = useLayoutContext()
  const direction = (isAtLeastMidSizeDesktop ? 'column' : 'row') as Direction

  const visibleAnchorItem = useSideMenuActiveItem({ items, offset, threshold })
  useScrollToAnchor()

  const isActive = useCallback(
    (item: { href?: string }) => {
      const sanitizedHref = item.href?.replace(new RegExp(SANITIZATION_REGEX), '')
      const sanitizedLocation = currentLocation.replace(new RegExp(SANITIZATION_REGEX), '')
      if (sanitizedHref?.startsWith('#')) {
        return visibleAnchorItem === sanitizedHref
      }
      return sanitizedLocation === sanitizedHref
    },
    [currentLocation],
  )

  const activeItem = useMemo(() => {
    const item = [...items].reverse().find((item) => isActive(item))

    if (item) {
      return item
    }

    for (const item of items) {
      for (const nestedItem of item.nestedItems || []) {
        if (isActive(nestedItem)) {
          return nestedItem
        }
      }
    }
  }, [items, isActive])

  const hasActiveNestedItem = useCallback(
    (nestedItems: { href?: string }[]) => {
      return nestedItems?.some((nestedItem) => isActiveItem(nestedItem))
    },
    [isActive],
  )

  const isActiveItem = useCallback((item: { href?: string }) => {
    return activeItem?.href === item.href
  }, [])

  const value = {
    isActiveItem,
    hasActiveNestedItem,
    currentLocation,
    direction,
    items,
  }

  return (
    <InPageNavigationContext.Provider value={value}>
      {direction === 'column' ? (
        <StickySideMenu currentLocation={currentLocation} items={items} {...otherProps} />
      ) : (
        <HorizontalNavigationTabs currentLocation={currentLocation} items={items} {...otherProps} />
      )}
    </InPageNavigationContext.Provider>
  )
}

export const useInPageNavigationContext = generateUseContext(InPageNavigationContext)
