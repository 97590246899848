import React from 'react'

import { clsx } from '@guiker/clsx'

import { Flex } from '../../../'
import { useUtilityStyle } from '../../../styles'
import { useInPageNavigationContext } from './InPageNavigation'
import { SideMenuItem, SideMenuItemProps } from './SideMenuItem'

type Item = Omit<SideMenuItemProps, 'currentLocation' | 'offset' | 'threshold'>

export type SideMenuProps = {
  className?: string
  currentLocation?: string
  direction?: 'column' | 'row'
  disabled?: boolean
  items: Item[]
  width?: number | string
}

export const SideMenu: React.FC<SideMenuProps> = ({ className, items, width = 200 }) => {
  const utilityStyles = useUtilityStyle({ maxWidth: width })
  const { direction } = useInPageNavigationContext()

  return (
    <Flex flexDirection={direction} className={clsx(className, direction === 'column' && utilityStyles.maxWidth)}>
      {items.map((itemProps) => (
        <SideMenuItem key={itemProps.href} {...itemProps} />
      ))}
    </Flex>
  )
}
