import React from 'react'

import { Grid } from '../../Layout'
import { MuiFormGroup, MuiFormGroupProps } from '../'
import { Input } from '../Input'
import { extractInputProps } from '../Input/extract-input-props'
import { Checkbox, CheckboxProps } from './Checkbox'

type DisplayDirection = 'row' | 'column'

type OptionsProps = CheckboxProps

interface CheckboxGroupProps extends MuiFormGroupProps {
  direction?: DisplayDirection
  disabled?: boolean
  error?: boolean
  errorMessage?: string
  helperText?: string
  label?: string
  maxWidth?: string | number
  name?: string
  options: OptionsProps[]
  readOnly?: boolean
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = (props) => {
  const { inputProps, otherProps } = extractInputProps(props)
  const { disabled, name, readOnly } = inputProps
  const { direction = 'column', maxWidth, options, ...other } = otherProps

  return (
    <Input {...inputProps}>
      <MuiFormGroup aria-label={name} {...inputProps} {...other}>
        <Grid container spacing={0} direction={direction}>
          {options
            .filter((o) => !!o)
            .map(({ label, ...checkboxProps }, index) => {
              return (
                <Grid item key={`checkbox-group-${index}`}>
                  <Checkbox
                    maxWidth={maxWidth}
                    disabled={disabled || checkboxProps.disabled}
                    readOnly={readOnly || checkboxProps.readOnly}
                    label={label as string}
                    name={`${name}.${index}`}
                    {...checkboxProps}
                  />
                </Grid>
              )
            })}
        </Grid>
      </MuiFormGroup>
    </Input>
  )
}

export { CheckboxGroup, CheckboxGroupProps }
