import React, { useState } from 'react'

import { clsx, makeStyles, theme } from '@guiker/components-core'
import { DropdownIcon } from '@guiker/icons'

import { Collapse } from '../../../'
import { BaseSideMenuItem } from './BaseSideMenuItem'
import { useInPageNavigationContext } from './InPageNavigation'
import { InPageNavigationItem } from './InPageNavigationItem'
import { NestedMenuItems } from './NestedMenuItems'

export type SideMenuItemProps = InPageNavigationItem & {
  nestedItems?: InPageNavigationItem[]
}

const useStyle = makeStyles(
  {
    icon: {
      width: 12,
      display: 'flex',
      alignItems: 'center',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),
    },
    iconCollapsed: {
      transform: 'rotate(0)',
    },
    iconExpanded: {
      transform: 'rotate(-90deg)',
    },
  },
  {
    name: 'SideMenuItem',
  },
)

export const SideMenuItem: React.FC<SideMenuItemProps> = ({
  nestedItems,
  defaultOpen,
  disabled,
  href,
  icon,
  ...props
}) => {
  const classes = useStyle()
  const { direction, isActiveItem, hasActiveNestedItem } = useInPageNavigationContext()
  const isActive = isActiveItem({ href }) || hasActiveNestedItem(nestedItems)
  const [open, setOpen] = useState(isActive)

  if (nestedItems?.length) {
    return (
      <>
        <BaseSideMenuItem
          href={href}
          disabled={disabled}
          onClick={(e) => {
            if (!disabled && !isActive) {
              e.preventDefault()
              setOpen(!open)
            }
          }}
          isActive={isActiveItem({ href })}
          hasActiveNestedItem={hasActiveNestedItem(nestedItems)}
          startAdornment={icon}
          endAdornment={
            direction === 'column' && (
              <DropdownIcon className={clsx(classes.icon, open ? classes.iconCollapsed : classes.iconExpanded)} />
            )
          }
          {...props}
        />
        <Collapse in={open} unmountOnExit>
          <NestedMenuItems items={nestedItems} disabled={disabled} rootHref={href} isRootItemActive={isActive} />
        </Collapse>
      </>
    )
  } else {
    return <BaseSideMenuItem href={href} disabled={disabled} isActive={isActive} {...props} />
  }
}
