import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'
import { CheckboxProps as MuiCheckboxProps } from '@material-ui/core/Checkbox'
import { default as FormControlLabel } from '@material-ui/core/FormControlLabel'

import { CheckboxAdornment, useDeviceType, useUtilityStyle } from '../../..'

export type BaseCheckboxProps = Omit<MuiCheckboxProps, 'color'> & {
  condensed?: boolean
  maxWidth?: string | number
  label?: string | React.ReactNode
  readOnly?: boolean
  helperText?: string
  error?: boolean
  errorMessage?: string
  checkboxClassName?: string
  iconClassName?: string
  labelPlacement?: 'start' | 'end'
  justifyContent?: 'space-between' | 'flex-start'
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      boxSizing: 'border-box',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(3),
      marginLeft: 0,
      alignItems: 'flex-start',
      gap: theme.spacing(2),
      '& > .MuiIconButton-root': {
        backgroundColor: 'inherit',
        color: 'inherit',
      },

      pointerEvents: ({ readOnly, disabled }: { readOnly: boolean; disabled: boolean }) =>
        readOnly || disabled ? 'none' : 'auto',
    },
    label: {
      ...theme.typography.variants.bodyMedium,
    },
    condensedLabel: {
      ...theme.typography.variants.bodySmall,
    },
    condensed: {
      alignItems: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingRight: theme.spacing(1.5),
      gap: theme.spacing(1),
    },
    readOnly: {
      borderColor: `${theme.palette.grey[60]} !important`,
      '&.MuiCheckbox-colorPrimary.Mui-checked': {
        backgroundColor: `${theme.palette.grey[60]} !important`,
      },
    },
  }),
  { name: 'BaseCheckbox' },
)

const BaseCheckbox: React.FC<BaseCheckboxProps> = (props) => {
  const {
    className,
    checked,
    condensed = false,
    defaultChecked,
    defaultValue,
    label,
    maxWidth = 360,
    readOnly,
    disabled,
    value,
    checkboxClassName,
    labelPlacement = 'end',
    error,
    errorMessage,
    helperText,
    id,
    ...other
  } = props

  const classes = useStyles({ readOnly, disabled })
  const { isMobile } = useDeviceType()
  const utilityClasses = useUtilityStyle({ maxWidth: !isMobile ? maxWidth : null })

  return (
    <FormControlLabel
      aria-label={`${value}`}
      className={clsx(classes.root, utilityClasses.maxWidth, className, { [classes.condensed]: condensed })}
      classes={{ label: clsx(classes.label, { [classes.condensedLabel]: condensed }) }}
      label={label}
      labelPlacement={labelPlacement}
      defaultChecked={defaultChecked}
      defaultValue={defaultValue}
      value={value}
      checked={checked}
      control={
        <CheckboxAdornment
          condensed={condensed}
          disabled={disabled}
          className={checkboxClassName}
          defaultChecked={defaultChecked}
          readOnly={readOnly}
          checked={checked}
          {...(other as any)}
        />
      }
    />
  )
}

export { BaseCheckbox }
