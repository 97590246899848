import React, { useState } from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, padding, theme } from '@guiker/components-core'
import { DateTime, ISO8601Date, parseDate, toISODate, Utils } from '@guiker/date'
import { useDateFormatter } from '@guiker/i18n'
import { CalendarIcon, ChevronDownIcon } from '@guiker/icons'
import { createMuiTheme } from '@material-ui/core'
import { KeyboardDatePicker, KeyboardDatePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides'
import { ThemeProvider } from '@material-ui/styles'

import { useDeviceType } from '../../..'
import { useUtilityStyle } from '../../../styles'
import { Typography } from '../../Data Display'
import { Flex } from '../../Layout'
import { TextField, TextFieldProps } from '../TextField'
import { CondensedDatePickerText, Pager } from './CondensedDatePickerText'

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P]
}

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

export type DatePickerProps = Omit<KeyboardDatePickerProps, 'value' | 'defaultValue' | 'onChange'> & {
  label?: string
  className?: string
  errorMessage?: string
  maxWidth?: string | number
  mb?: number
  textFieldProps?: TextFieldProps
  defaultValue?: ISO8601Date
  value?: ISO8601Date
  onChange?: (date: ISO8601Date, value?: string) => void
  hoverStateBackground?: string
  condensed?: boolean
  minDate?: ISO8601Date
  maxDate?: ISO8601Date
  pager?: {
    step: 'date' | 'month' | 'year'
    format: string
  }
}

const styles = {
  color: theme.palette.text.primary.main,
  hoverState: {
    backgroundColor: theme.palette.grey[10],
  },
  selectedState: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}

export const datePickerTheme = ({
  hoverStateBackground,
  hasInputStyle,
}: {
  hoverStateBackground?: string
  hasInputStyle?: boolean
}) => ({
  MuiPickersCalendarHeader: {
    switchHeader: {
      paddingTop: theme.spacing(2),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      pointerEvents: 'none',
      '& > button': {
        pointerEvents: 'auto',
      },
    },
    transitionContainer: {
      display: 'none',
    },
  },
  MuiPickersBasePicker: {
    container: {
      padding: padding(0, 2),
    },
  },
  MuiPickersDay: {
    day: {
      color: styles.color,
    },
    daySelected: {
      ...styles.selectedState,
      '&:hover': styles.selectedState,
    },
    dayDisabled: {
      color: theme.palette.grey[30],
    },
    current: {
      color: styles.color,
    },
  },
  MuiPickersYear: {
    root: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      fontSize: 16,
      borderRadius: 40,
      '&:hover': styles.hoverState,
      '&:first-child': {
        marginTop: 40,
      },
    },
    yearSelected: {
      ...styles.selectedState,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      fontSize: 16,
      '&:hover': styles.selectedState,
    },
  },
  MuiPickersMonth: {
    root: {
      height: 54,
      fontSize: 16,
      borderRadius: 40,
      '&:hover': styles.hoverState,
    },
    monthSelected: {
      ...styles.selectedState,
      fontSize: 16,
      '&:hover': styles.selectedState,
    },
  },
  MuiButton: {
    label: {
      color: styles.color,
    },
  },
  ...(hasInputStyle && {
    MuiInputBase: {
      root: {
        backgroundColor: theme.palette.grey[5],
        color: styles.color,
        '&:hover': {
          backgroundColor: hoverStateBackground ? `${hoverStateBackground} !important` : theme.palette.grey[10],
          cursor: 'pointer',
        },
      },
      input: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  }),
})

const useStyles = makeStyles({
  root: {},
  readOnly: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  toolbar: {
    width: '70%',
    maxWidth: 200,
    zIndex: 9999,
    position: 'relative',
    margin: '0 auto',
    marginTop: 30,
    marginBottom: -58,
  },
  toolbarYearSelector: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
    color: theme.palette.primary.main,
    '& > p': {
      margin: 0,
      marginRight: 12,
    },
  },
  toolbarYearSelectorMonth: {
    marginBottom: theme.spacing(3),
  },
  calendarIcon: {
    cursor: 'pointer',
  },
})

export const DatePicker: React.FC<DatePickerProps> = ({
  className,
  onChange: propsOnChange = () => {},
  label,
  value,
  defaultValue,
  condensed = false,
  disabled = false,
  error = false,
  errorMessage,
  helperText,
  id,
  inputVariant = 'filled',
  variant = 'dialog',
  format = 'dd / MMM / yyyy',
  size = 'medium',
  autoOk = true,
  readOnly = false,
  maxWidth,
  mb = 0,
  hoverStateBackground,
  views = ['year', 'month', 'date'],
  minDate,
  maxDate,
  pager: pagerProp,
  ...props
}) => {
  const classes = useStyles()
  const { formatDate } = useDateFormatter()
  const transformedDatePickerTheme = createMuiTheme({
    overrides: { ...(datePickerTheme({ hoverStateBackground }) as MuiPickersOverrides) },
  })
  const { isMobile } = useDeviceType()
  const [open, setOpen] = useState(false)
  const utilityClasses = useUtilityStyle({ maxWidth: !isMobile ? maxWidth : null })
  const parsedValue = parseDate(value || defaultValue)

  const [toolbarDate, setToolbarDate] = useState(parsedValue)

  const openCalendar = (e: React.FocusEvent<HTMLInputElement>) => {
    const input = e.target as HTMLElement
    e.preventDefault()
    input.blur()

    if (!readOnly && !disabled) {
      setToolbarDate(parsedValue)
      setOpen(true)
    }
  }

  const onChange = (value: DateTime | ISO8601Date) => {
    propsOnChange(toISODate(value))
  }

  const pager: Pager =
    !!pagerProp && !!minDate && !!maxDate
      ? {
          format: pagerProp.format,
          onJumpPrev: () => onChange(minDate),
          onPrev: (currentDate?: DateTime) => {
            const date = currentDate ?? DateTime.local()
            const exec: { [k in (typeof pagerProp)['step']]: () => DateTime } = {
              date: () => date.minus({ day: 1 }),
              month: () => date.minus({ month: 1 }),
              year: () => date.minus({ year: 1 }),
            }
            onChange(exec[pagerProp.step]())
          },
          onNext: (currentDate?: DateTime) => {
            const date = currentDate ?? DateTime.local()
            const exec: { [k in (typeof pagerProp)['step']]: () => DateTime } = {
              date: () => date.plus({ day: 1 }),
              month: () => date.plus({ month: 1 }),
              year: () => date.plus({ year: 1 }),
            }
            onChange(exec[pagerProp.step]())
          },
          onJumpNext: () => onChange(maxDate),
        }
      : undefined

  return (
    <ThemeProvider theme={transformedDatePickerTheme}>
      <MuiPickersUtilsProvider utils={Utils}>
        <KeyboardDatePicker
          minDate={minDate}
          maxDate={maxDate}
          clearable={true}
          readOnly={readOnly}
          value={parsedValue}
          disabled={disabled}
          format={format}
          inputVariant={inputVariant}
          size={size}
          error={error}
          variant={variant}
          onAccept={onChange}
          onChange={onChange}
          onClose={() => setOpen(false)}
          open={open}
          onKeyPress={(e) => e.preventDefault()}
          onMonthChange={(date) => setToolbarDate(date)}
          onYearChange={(date) => setToolbarDate(date)}
          autoOk={autoOk}
          views={views}
          className={clsx([classes.root, className, utilityClasses.maxWidth, { [classes.readOnly]: readOnly }])}
          TextFieldComponent={({ InputProps, defaultValue, onFocus, inputRef, value, ...props }) =>
            condensed || pager ? (
              <CondensedDatePickerText
                value={parsedValue}
                disabled={readOnly || disabled}
                errorMessage={error && errorMessage}
                onClick={openCalendar}
                name={props.name}
                format={format}
                pager={pager}
              />
            ) : (
              <TextField
                label={label}
                onFocus={openCalendar}
                endAdornment={
                  !readOnly && (
                    <CalendarIcon
                      className={classes.calendarIcon}
                      onClick={(e: React.FocusEvent<HTMLInputElement>) => openCalendar(e)}
                      color={disabled ? 60 : 'textPrimary'}
                    />
                  )
                }
                error={error}
                errorMessage={errorMessage}
                helperText
                maxWidth={maxWidth}
                mb={mb}
                value={formatDate(parsedValue) || (readOnly && '-') || null}
                inputRef={inputRef}
                {...(props as unknown as TextFieldProps)}
              />
            )
          }
          ToolbarComponent={({ openView, setOpenView }) => (
            <div className={classes.toolbar}>
              {['date', 'month'].includes(openView) && (
                <Flex
                  tabIndex={0}
                  className={clsx([
                    classes.toolbarYearSelector,
                    { [classes.toolbarYearSelectorMonth]: openView === 'month' },
                  ])}
                  onClick={() => setOpenView('year')}
                >
                  <Typography variant='bodyBold'>{(toolbarDate || DateTime.local()).toFormat('LLLL yyyy')}</Typography>
                  <ChevronDownIcon />
                </Flex>
              )}
            </div>
          )}
          {...props}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}
