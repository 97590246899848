import React from 'react'

import { TOptions } from 'i18next'
import { Trans, useTranslation } from 'react-i18next'

type TransProps<K extends string> = React.PropsWithChildren & {
  i18nKey?: K | K[]
  values?: {}
}

export const buildUseT =
  ({ domain }: { domain: string }) =>
  (args?: Omit<Parameters<typeof useT>[0], 'domain'>) =>
    useT({ ...args, domain })

export const useT = ({
  domain,
  entity,
  screenName,
  componentName,
}: { domain?: string; screenName?: string; entity?: string; componentName?: string } = {}) => {
  const tCommonNamespace = 'common:'
  const tSharedNamespace = `common-${domain}`
  const tMainNamespace = `main-${domain}`
  const tOfficeNamespace = `office-${domain}`
  const [_t] = useTranslation([tCommonNamespace, tSharedNamespace, tMainNamespace, tOfficeNamespace])

  const tSharedPrefix = entity ? `${tSharedNamespace}:${entity}.` : `${tSharedNamespace}:`
  const tComponentPrefix = componentName ? `${tSharedNamespace}:components.${componentName}.` : `${tSharedNamespace}:`
  const tOfficePrefix = screenName ? `${tOfficeNamespace}:${screenName}.` : `${tOfficeNamespace}:`
  const tMainPrefix = screenName ? `${tMainNamespace}:${screenName}.` : `${tMainNamespace}:`

  const t = ((key: string, { nullIfEmpty, ...options }: TOptions & { nullIfEmpty?: boolean } = {}) => {
    const result = _t(key, options)

    if (nullIfEmpty && result === key.split(':').pop()) {
      return null
    }

    return result
  }) as typeof _t

  return {
    t,
    tComponents: (key: string, options?: TOptions) => t(`${tComponentPrefix}${key}`, options),
    tBase: (key: string, options?: TOptions) => t(`${tCommonNamespace}${key}`, options),
    tShared: (key: string, options?: TOptions) => t(`${tSharedPrefix}${key}`, options),
    tOffice: (key: string, options?: TOptions) => t(`${tOfficePrefix}${key}`, options),
    tMain: (key: string, options?: TOptions) => t(`${tMainPrefix}${key}`, options),
    tPrefix: {
      components: tComponentPrefix,
      base: tCommonNamespace,
      shared: tSharedPrefix,
      office: tOfficePrefix,
      main: tMainPrefix,
    },
    Trans: <T extends string>({ i18nKey, ...props }: TransProps<T>) => (
      <Trans i18nKey={`${tCommonNamespace}${i18nKey}`} {...props} />
    ),
    SharedTrans: <T extends string>({ i18nKey, ...props }: TransProps<T>) => (
      <Trans i18nKey={`${tSharedPrefix}${i18nKey}`} {...props} />
    ),
    MainTrans: <T extends string>({ i18nKey, ...props }: TransProps<T>) => (
      <Trans i18nKey={`${tMainPrefix}${i18nKey}`} {...props} />
    ),
    OfficeTrans: <T extends string>({ i18nKey, ...props }: TransProps<T>) => (
      <Trans i18nKey={`${tOfficePrefix}${i18nKey}`} {...props} />
    ),
  }
}
