import * as React from 'react'

import LazyLoad, { LazyLoadProps } from 'react-lazyload'

import { clsx } from '@guiker/clsx'
import { makeStyles } from '@guiker/components-core'

type ObjectFitProp = 'contain' | 'cover'

export type ImageProps = {
  className?: string
  image?: string
  src?: string
  alt?: string
  width: string | number
  height: string | number
  offset?: number
  objectFit?: ObjectFitProp
}

const useStyles = makeStyles({
  root: {
    objectFit: ({ objectFit }: { objectFit: ObjectFitProp }) => objectFit,
  },
})

const Image: React.FC<ImageProps> = ({ className, height, offset = 300, src, alt, objectFit = 'contain' }) => {
  const classes = useStyles({ objectFit })

  const LazyLoadComponent = LazyLoad as unknown as React.FC<LazyLoadProps & React.PropsWithChildren>

  return (
    <LazyLoadComponent height={height} offset={offset}>
      <img className={clsx(className, classes.root)} src={src} alt={alt} />
    </LazyLoadComponent>
  )
}

export { Image }
