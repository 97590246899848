import React from 'react'

import { PageLayout, PageLayoutProps } from '../../Layout'
import { ShiftedDrawerContent } from '../Drawer'
import { ButtonContainer, ButtonContainerProps } from './ButtonContainer'

export type StepProps = React.PropsWithChildren &
  Omit<PageLayoutProps, 'forceTakeover'> &
  Omit<ButtonContainerProps, 'title' | 'onClickNext'> & {
    onClickNext?: (args?: { asLastStep?: boolean }) => void
    hasDrawerNav?: boolean
    isDrawerMinimized?: boolean
    isNested?: boolean
    isContentDrawerOpened?: boolean
    skip?: boolean
  }

const Step: React.FC<StepProps> = ({
  children,
  hasBackButton,
  hasDrawerNav = false,
  hasButtonContainer = true,
  isDrawerMinimized,
  hasNextButton,
  backLabel,
  onClickBack,
  nextDisabled,
  nextLabel,
  nextButtonType,
  onClickNext,
  isSubmitting,
  errors,
  isNested = false,
  progression,
  isContentDrawerOpened,
  ...pageLayoutProps
}) => {
  const nestedProps = isNested ? { maxWidth: 1440, pageLayoutProps: { noPadding: true } } : {}

  return (
    <PageLayout
      maxWidth={nestedProps.maxWidth ?? 900}
      hasButtonContainer={true}
      {...pageLayoutProps}
      {...nestedProps.pageLayoutProps}
    >
      <ShiftedDrawerContent isDrawerOpen={isContentDrawerOpened}>{children}</ShiftedDrawerContent>
      {hasButtonContainer && (
        <ButtonContainer
          maxWidth={nestedProps.maxWidth ?? 900}
          hasBackButton={hasBackButton}
          hasNextButton={hasNextButton}
          backLabel={backLabel}
          onClickBack={onClickBack}
          onClickNext={onClickNext}
          nextDisabled={nextDisabled}
          nextLabel={nextLabel}
          nextButtonType={nextButtonType}
          isSubmitting={isSubmitting}
          errors={errors}
          hasDrawerNav={hasDrawerNav}
          isDrawerMinimized={isDrawerMinimized}
          progression={progression}
        />
      )}
    </PageLayout>
  )
}

export { Step }
