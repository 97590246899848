import React from 'react'

import { clsx } from '@guiker/clsx'
import { makeStyles, toPx } from '@guiker/components-core'

import { Flex } from '../../Layout'

export const useStyles = makeStyles(
  (theme) => ({
    hidden: {
      visibility: 'hidden',
      height: 0,
    },
    bottomLine: {
      minHeight: 9,
      maxHeight: 9,
      marginBottom: toPx(5),
    },
    lineHidden: {
      visibility: 'hidden',
    },
    line: {
      width: 1,
      height: '100%',
      backgroundColor: theme.palette.grey[15],
    },
    fullLine: {
      minHeight: 36,
      maxHeight: 36,
    },
    topLine: {
      minHeight: 9,
      maxHeight: 9,
      marginTop: toPx(5),
    },
  }),
  { name: 'ActivityTracker' },
)

type ActivityTrackerItemProps = {
  label: React.ReactNode
  content?: React.ReactNode
  marker: {
    isFirst: boolean
    isLast: boolean
    icon: React.ReactNode
  }
}

type ActivityTrackerProps = {
  items: ActivityTrackerItemProps[]
  spacing?: number
}

export const ActivityTracker: React.FC<ActivityTrackerProps> = ({ items, spacing }) => (
  <Flex flexDirection='column-reverse'>
    {items?.map((item) => {
      const classes = useStyles()
      const { label, content, marker } = item

      return (
        <Flex flexDirection='column'>
          <Flex alignItems='center'>
            <Flex flexDirection='column' alignItems='center'>
              <div className={clsx(classes.line, classes.bottomLine, marker.isLast && classes.lineHidden)} />
              {marker.icon}
              <div className={clsx(classes.line, classes.topLine, marker.isFirst && classes.lineHidden)} />
            </Flex>
            <Flex ml={spacing ?? 2}>{label}</Flex>
          </Flex>

          <Flex>
            <Flex flexDirection='column' alignItems='center'>
              <div className={clsx(classes.hidden)}>{marker.icon}</div>
              <div className={clsx(classes.line, classes.fullLine, marker.isFirst && classes.lineHidden)} />
            </Flex>
            {!!content && <Flex ml={spacing ?? 2}>{content}</Flex>}
          </Flex>
        </Flex>
      )
    })}
  </Flex>
)
